import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {Order, Orders} from "../classes/order";
import {HttpService} from "../../services/http.service";
import {ProductService} from "./product.service";
import {DataSharingService} from '../../services/data-sharing.service';


const state = {
    checkoutItems:  JSON.parse(localStorage['checkoutItems'] || '[]'),
    transactions:   JSON.parse(localStorage['transactions'] || '{}'),
}

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    order = new Orders();
    public orderDetails: Order = {};
    items: any [] = [];
    preferredLanguageSubscription: Subscription;

    constructor(
        private router: Router,
        private service: HttpService,
        private ProductService: ProductService,
        private dataSharingService: DataSharingService,
    ) {
        this.socket();
    }

    // Get Checkout Items
    public get checkoutItems(): Observable<any> {
        const itemsStream = new Observable(observer => {
            observer.next(state.checkoutItems);
            observer.complete();
        });
        return <Observable<any>>itemsStream;
    }

    // Create order
    // public createOrder(product: any, details: any, orderId: any, amount: any) {
    public createOrder(data) {
        let item = {
            shippingDetails: data.details,
            product: data.product,
            orderId: data.orderId,
            totalAmount: data.amount,
            subtotal: data.subtotal,
            tax: data.tax || 0,
            service_fee: data.serviceFee || 0,
            used_credits: data.used_credits || 0,
        };
        state.checkoutItems = item;
        localStorage.setItem("checkoutItems", JSON.stringify(item));
        // localStorage.removeItem("cartItems");
        // this.router.navigate(['/shop/checkout/success', orderId]);
        return state;
    }

     processOrder(usuario, type = 0) {
        let user = usuario;
        console.log("userLocal: ", user);
        this.checkoutItems.subscribe(response => {
            let uid = localStorage.getItem('orderId');
            if (uid == '' || uid == undefined ){
                uid = Date.now().toString();
                localStorage.setItem('orderId', uid);
            }
            console.log('uid', uid);
            const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            const WEEKS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            let now = new Date();
            let formatdate = WEEKS[now.getDay()] + ", " + now.getDate() + " of " + MONTHS[now.getMonth()] + " of " + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes();
            console.log('response checkoutItems', response);
            // this.orderDetails = response;
            // localStorage.setItem('orderDetails', JSON.parse(response));
            this.order.amount = Number(response.subtotal.toFixed(2));
            response.product.forEach(element => {
                this.items.push({
                    image: element.images[0].src,
                    name: element.title,
                    quantity: element.quantity,
                    price: element.price_tax_product_discount,
                    discount: element.product_promote ? element.discount : 0,
                    sum: +(element.price_tax_product_discount * element.quantity).toFixed(2),
                    subtotal: +(element.price_tax_product_discount * element.quantity).toFixed(2),
                    uid: element.id,
                    description: element.description,
                    category_uid: element.category_uid,
                });
            })
            this.order.items = this.items;
            this.order.who_receives = response.shippingDetails.firstname + ' ' + response.shippingDetails.lastname;
            this.order.uid = uid;
            this.order.user_uid = user.uid;
            this.order.commerce_uid = '';
            this.order.created_at_formatted = formatdate;
            this.order.customer_payment_uid = '';
            this.order.discount = 0;
            this.order.deparment = '';
            this.order.discount_percent = 0;
            this.order.tax = Number(response.tax.toFixed(2)) || 0;
            this.order.service_fee = Number(response.service_fee.toFixed(2)) || 0;
            this.order.used_credits = Number(response.used_credits.toFixed(2)) || 0;
            this.order.tip_porcent = '0';
            this.order.total = response.totalAmount.toFixed(2) * 1;
            this.order.totalInCents = response.totalAmount.toFixed(2) * 100;
            this.order.tip_amount = 0;
            this.order.isGift = false;
            this.order.is_bitcoin = false;
            this.order.is_card = false;
            this.order.is_delivery = true;
            this.order.is_notified_client = true;
            this.order.is_notified_commerce = false;
            this.order.need_invoice = false;
            this.order.order_active = true;
            this.order.status_user = true;
            this.order.manager_uid = '';
            this.order.orders_status_uid = type == 0 ? 'New' : 'Payment Request';
            this.order.municipality = '';
            this.order.waytopay = localStorage.getItem('payment');
            this.order.invoicing = localStorage.getItem('invoicing');
            this.order.commerce_name        = localStorage.getItem('sucursal');
            this.order.commerce_direction   = localStorage.getItem('commerce_direction');
            this.order.shipping = localStorage.getItem('shipping');
            this.order.isWeb = true; // siempre sera true porque es proyecto desde la tienda web
            this.order.transactions = state.transactions; // added 03-05-2023
            console.log('info order send', this.order);

            let order_uid = localStorage.getItem('order_uid');
            console.log('order_uid', order_uid);
            if (order_uid == '' || order_uid == null){
                this.saveOrder().then( async res => {
                    if (localStorage.getItem("payment") != 'Wompi') {
                        localStorage.removeItem("cartItems");
                        localStorage.removeItem("orderId");
                        // window.location.href = `/shop/checkout/success/${localStorage.getItem('order_uid')}`;
                        await this.router.navigate(['/shop/checkout/success', localStorage.getItem('order_uid')]);
                    }
                });
            } else {
                this.updateOrder(order_uid).then( async res => {
                    if (localStorage.getItem("payment") != 'Wompi') {
                        localStorage.removeItem("cartItems");
                        localStorage.removeItem("orderId");
                        // window.location.href = `/shop/checkout/success/${localStorage.getItem('order_uid')}`;
                        await this.router.navigate(['/shop/checkout/success', localStorage.getItem('order_uid')]);
                    }
                });
            }

        });

    }

    saveOrder() {
        return new Promise((resolve, reject) => {
            let dataSave: any;
            this.service.saveOrder(this.order).then((res) => {
                dataSave = res;
                console.info('Save order', res)
                this.getStock().then(stock => {
                    setTimeout(() => {
                        console.log("update: ", stock);
                        this.updateStock(stock).then(res => {
                            console.log("update: ", stock);
                        })
                    }, 1e3);
                });
                console.log('dataSave._path.segments[1]', dataSave._path.segments[1])
                localStorage.setItem('order_uid', dataSave._path.segments[1])
                console.log(`localStorage.getItem('order_uid')`, localStorage.getItem('order_uid'))
                // localStorage.removeItem("cartItems");
                resolve(res);
            }, error => {
                reject(error);
                console.error('Error to get storing item', error)
            });
        })

    }

    updateOrder(order_uid){
        return new Promise((resolve, reject) => {
            this.service.updateOrder(this.order, order_uid).then( res => {
                console.log('order update', res);
                this.getStock().then(stock => {
                    setTimeout(() => {
                        console.log("update: ", stock);
                        this.updateStock(stock).then(response => {
                            console.log("update: ", stock);
                            resolve(response);
                        })
                    }, 1e3);
                });
            })
        })
    }

    getStock(type = 0) {
        let update = [];
        let new_products_stock;
        return new Promise((resolve, reject) => {
            this.order.items.forEach(element => {
                this.service.getOneProduct(element.uid).subscribe(res => {
                    console.log("sendGET_One_Product: ", res.products_stock, " - ", res.product_name);
                    new_products_stock = res.products_stock - element.quantity;
                    res.products_stock = new_products_stock;
                    if (new_products_stock > 0) {
                        update.push(res);
                    } else {
                        res.product_availability = 'Out of Stock';
                        res.product_background_availability = 'danger';
                        update.push(res);
                    }
                });
            });
            resolve(update);
        })
    }

    updateStock(stock) {
        return new Promise((resolve, reject) => {
            stock.forEach(element => {
                this.service.sendPUT_Product(element, element.id).then(res => {
                    console.log("sendPUT_Product: ", res);
                });
            });
            resolve(stock)
        })
    }

    socket(){
        this.preferredLanguageSubscription = this.dataSharingService.transactions.subscribe((valor: any) => {
            state.transactions = valor;
        });
    }

}
