import {Component, OnInit, ViewChild} from '@angular/core';
import {Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import {HttpService} from "../services/http.service";
import {element} from "protractor";
import {ViewportScroller} from "@angular/common";
import {FirestoreService} from "../services/firestore.service";
import {ProductService} from "../shared/services/product.service";
import Swal from "sweetalert2";

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

    public url: string = '';
    public themeLogo: string = 'https://firebasestorage.googleapis.com/v0/b/ch-tienda-online-app-dev.appspot.com/o/assets%2Fimages%2Flogos%2Fyourlogo.png?alt=media&token=1e815d03-1b4c-47a0-9f8e-55ba3ee7d92c';
    public id: string = '';
    @ViewChild('boxSearch') targetElement: any;
    productsSearch: any[] = [];
    resultSearch: any[] = [];
    userSearch: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        private firestore: FirestoreService,
        public productService: ProductService,
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) this.url = event.url;
            this.id = this.url.substring(this.url.lastIndexOf('/') + 1);
        });
        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            this.themeLogo      = res.header.logo.default;
        });
    }

    ngOnInit(): void {
        // this.getProductsForSearch();
    }

    filter(event) {
        this.resultSearch = this.productsSearch.filter(item => item.title.toLowerCase().indexOf(event.toLowerCase()) > -1);
        Swal.fire('Exito', 'Búsqueda con éxito', 'success');
        this.userSearch = true;
        let top = this.targetElement.nativeElement.offsetTop;
        // console.log('top ', top)
        this.viewScroller.scrollToPosition([0, top]);
    }

    getProductsForSearch() {
        this.firestore.getWhere('Products', 'product_active', true).subscribe(res => {
            res.forEach(element => {
                if (element.product_available && element.product_active) {
                    let img = {
                        "src": '',
                        "alt": element.product_name,
                    }
                    element.title = element.product_name;
                    element.description = element.product_description || element.specifications;
                    element.brand = element.marca;
                    element.sale = element.product_active;
                    element.price   = element.price_tax_product;
                    element.new = false;
                    if (element.product_promote) element.discount = element.product_discount || element.discount;
                    element.stock = element.product_stock || element.products_stock;
                    element.images = [];
                    if (element.extra_images != null) {
                        element.extra_images.forEach(index => {
                            img.src = index;
                            element.images.push(img);
                        })
                    }
                    img.src = element.product_imagesm;
                    element.images.push(img);
                    this.productsSearch.push(element);
                }
            });
        })
    }

}
