import {Component, PLATFORM_ID, Inject} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {LoadingBarService} from '@ngx-loading-bar/core';
import {map, delay, withLatestFrom} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {HttpService} from "./services/http.service";
import {BrowserService} from "./services/browser.service";
import {DataSharingService} from './services/data-sharing.service';
import {toJSDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import {Subscription} from 'rxjs';
import {FirestoreService} from './services/firestore.service';
import {compileInjectable} from '@angular/compiler';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    // For Progressbar
    loaders = this.loader.progress$.pipe(
        delay(1000),
        withLatestFrom(this.loader.progress$),
        map(v => v[1]),
    );

    themeDeafult: string = '#FF4C3B';
    maintenance_page: boolean = false;
    Year = new Date().getFullYear();
    logo: string = '';
    txtLabel: string = '';
    browserName: string;
    sub: Subscription;
    collectionName = 'Settings';
    collectionField = 'transactions';
    orderByDir: any = 'asc';
    public showLogoWhatsapp: boolean = false;
    public phoneWhatsapp: number = 0;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private loader: LoadingBarService, translate: TranslateService,
        private service: HttpService,
        private browserService: BrowserService,
        private dataSharingService: DataSharingService,
        private firestoreService: FirestoreService,
    ) {
        this.browserName = browserService.getBrowserName();
        if (isPlatformBrowser(this.platformId)) {
            translate.setDefaultLang('en');
            translate.addLangs(['en', 'fr']);
        }
        this.getSettingMaintenancePage();
        this.getSettingStore();
        this.sub = this.firestoreService.search(this.collectionName, {
            'orderByName': this.collectionField,
            'orderByDir': this.orderByDir,
        }).subscribe(res1 => {
            this.getSettingGeneral();
        })
    }

    ngOnInit(): void {
        document.documentElement.style.setProperty('--theme-deafult', this.themeDeafult );
    }

    getSettingStore(){
        this.service.sendGET_SettingsApp('Store').subscribe((res) => {
            if (res.themeDeafult != undefined){
                this.themeDeafult = res?.themeDeafult.toLocaleUpperCase();
            }
            console.log('Store', res);
            if (res?.currency != undefined){
                let newCurrencyMap = new Map<string, any>();
                newCurrencyMap.set('name', res?.currency.name);
                newCurrencyMap.set('currency', res?.currency.currency);
                newCurrencyMap.set('price', res?.currency.price);
                this.dataSharingService.currency.next(newCurrencyMap);


                const mapAsString = JSON.stringify([...newCurrencyMap]);

                localStorage.setItem('currency', mapAsString);
            }

            if (res?.whatsapp != undefined){
                 this.showLogoWhatsapp = res.whatsapp.active;
                 this.phoneWhatsapp = res.whatsapp.phone;
                 this.dataSharingService.phoneWhatsapp.next(this.phoneWhatsapp);
                 this.dataSharingService.logoWhatsapp.next(res.whatsapp.logo);
                 localStorage.setItem('phoneWhatsapp',String(this.phoneWhatsapp));
                 localStorage.setItem('logoWhatsapp',String(res.whatsapp.logo));
            }
        });
    }

    getSettingGeneral() {
        this.service.sendGET_SettingsApp('General').subscribe(res => {
            this.storeSettingsInLocalStorage(res);
            this.emitValuesUsingSubjects(res);
        });
    }

    storeSettingsInLocalStorage(res){
        const view_tax = res.transactions?.view_tax ?? false;
        const include_tax = res.transactions?.include_tax ?? false;
        const current_tax = res.transactions?.tax ?? 0;
        const include_service_fee = res.transactions?.include_service_fee ?? false;
        const unique_tax = res.transactions?.unique_tax ?? false;
        const isWallet = res?.isWallet ?? false;
        const service_fee_percent = res.transactions?.service_fee_percent ?? 0;
        const service_fee_limit = res.transactions?.service_fee_limit ?? 0;
        const transactions = res.transactions || {};

        // Se almacena el valor en el local storage
        localStorage.setItem('currentMoney', res.currentMoney);
        localStorage.setItem('appName', res.appName);
        localStorage.setItem('primary_color', res?.ionic_color.ion_color_base);
        localStorage.setItem('secondary_color', res?.ionic_color_second.ion_color_base);

        localStorage.setItem('include_tax', include_tax);
        localStorage.setItem('current_tax', current_tax);
        localStorage.setItem('view_tax', view_tax);
        localStorage.setItem('isWallet', isWallet);
        localStorage.setItem('unique_tax', unique_tax);
        localStorage.setItem('include_service_fee', include_service_fee);
        localStorage.setItem('service_fee_percent', service_fee_percent);
        localStorage.setItem('service_fee_limit', service_fee_limit);
        localStorage.setItem('transactions', JSON.stringify(transactions));
    }

    emitValuesUsingSubjects(res){
        const view_tax = res.transactions?.view_tax ?? false;
        const include_tax = res.transactions?.include_tax ?? false;
        const current_tax = res.transactions?.tax ?? 0;
        const include_service_fee = res.transactions?.include_service_fee ?? false;
        const unique_tax = res.transactions?.unique_tax ?? false;
        const isWallet = res?.isWallet ?? false;
        const service_fee_percent = res.transactions?.service_fee_percent ?? 0;
        const service_fee_limit = res.transactions?.service_fee_limit ?? 0;
        const transactions = res.transactions || {};

        // Se emite el valor actualizado a través del Subject definido en el servicio MySharedService
        this.dataSharingService.currentMoneySubject.next(res.currentMoney);
        this.dataSharingService.currentTaxSubject.next(current_tax);
        this.dataSharingService.include_tax.next(include_tax);
        this.dataSharingService.include_service_fee.next(include_service_fee);
        this.dataSharingService.service_fee_percent.next(service_fee_percent);
        this.dataSharingService.service_fee_limit.next(service_fee_limit);
        this.dataSharingService.view_tax.next(view_tax);
        this.dataSharingService.unique_tax.next(unique_tax);
        this.dataSharingService.isWallet.next(isWallet);

        this.dataSharingService.appName.next(res.appName);
        this.dataSharingService.primary_color.next(res.ionic_color.ion_color_base);
        this.dataSharingService.secondary_color.next(res.ionic_color_second.ion_color_base);
        this.dataSharingService.transactions.next(transactions);
    }

    getSettingMaintenancePage(){
        this.service.sendGET_SettingsApp('MaintenancePage').subscribe((res) => {
            // console.log('MaintenancePage',res )
            this.maintenance_page = res.maintenance_page;
            this.logo             = res.image;
            this.txtLabel         = res.text_label;
        });
    }

}
