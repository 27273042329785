import {Component, Inject, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {HttpService} from "../../services/http.service";
import {element} from "protractor";
import {environment} from "../../../environments/environment";
import {isPlatformBrowser, ViewportScroller} from "@angular/common";
import {ProductService} from "../../shared/services/product.service";
import {FirestoreService} from "../../services/firestore.service";
import {Options} from "ng5-slider";
import Swal from "sweetalert2";

@Component({
    selector: 'app-resultado',
    templateUrl: './resultado.component.html',
    styleUrls: ['./resultado.component.scss']
})

export class ResultadoComponent {
    public urlCategory: string = '';
    public urlSubcategory: string = '';

    public grid: string = 'col-xl-3 col-md-6';
    public layoutView: string = 'grid-view';
    public all_products: any[] = [];
    public products: any[] = [];
    public brands: any[] = [];
    public colors: any[] = [];
    public size: any[] = [];
    public minPrice: number = 0;
    public maxPrice: number = 1;
    public minDE: number = 0;
    public maxDE: number = 0;
    public minDI: number = 0;
    public maxDI: number = 0;
    public minL: number = 0;
    public maxL: number = 0;
    public tags: any[] = [];
    public category: string;
    public pageNo: number = 1;
    public paginate: any = {}; // Pagination use only
    public sortBy: string; // Sorting Order
    public mobileSidebar: boolean = false;
    public loader: boolean = true;
    public finished: boolean = false  // boolean when end of data is reached
    public addItemCount = 8;
    public url: string = '';
    public urlID: string = '';
    public loadGif: string = environment.loadGif;
    public showLoading: boolean = true;
    public infoSubcategory = {
        "id": '',
        "bannerSubcategory": environment.bannerDefault || 'assets/images/collection-banner.jpg',
        "nameSubcategory": '',
        "subcategory_description": 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        "subcategory_highlights_description": 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and\n' +
            'scrambled it to make a type specimen book. It has\n' +
            'survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum\n' +
            'passages,\n' +
            'and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    };
    public data = {
        'type': 2,
        'url': '',
        'offset': 1,
        'limit': 50,
        'page': 1,
    };
    public sendBreadcrumbDynamic: any [] = [];
    public categories: any [] = [];
    public allCategories: any [] = [];
    public subcategories: any [] = [];
    public classifications: any [] = [];
    public optionsDE: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsDI: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsL: Options = {
        floor: 0,
        ceil: 500,
    };
    public optionsPrice: Options = {
        floor: 0,
        ceil: 1e3,
    };
    public collapse: boolean = true;
    public isBrowser: boolean = false;
    public loading: boolean = false;
    public search: string = '';
    productsSearch: any[] = [];
    resultSearch: any[] = [];
    userSearch: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private route: ActivatedRoute,
        private router: Router,
        private service: HttpService,
        private viewScroller: ViewportScroller,
        public productService: ProductService,
        private firestore: FirestoreService
    ) {
        if (isPlatformBrowser(this.platformId)) this.isBrowser = true;
        this.router.events.subscribe(event => {
            this.search = this.route.snapshot.paramMap.get('search');
            this.url = this.router.url;
            console.log('search', this.search);
            console.log('url', this.url);
            if (event instanceof NavigationEnd) {
                Swal.fire({
                    icon: 'info',
                    title: 'Buscando producto, por favor espere un momento',
                    showConfirmButton: false,
                    timer: 2e3
                });
                this.getProductsForSearch();
            }
        })
        this.getProductsForSearch();
        // let arrayBreadcrumb = this.url.split('/');
        // this.sendBreadcrumbDynamic = environment.breadcrumbDynamic(arrayBreadcrumb);

        /*this.service.getSubcategoryByUrl(this.urlSubcategory).subscribe(res => {
            console.log('getSubcategoryByUrl', res);

            this.classifications = res.classifications;
            this.infoSubcategory.id = res.subcategory.id;
            this.infoSubcategory.nameSubcategory = res.subcategory.name;
            console.log('infoSubcategory.id', this.infoSubcategory.id);


            // this.firestore.getWhere('Products','subcategory_uid',this.infoSubcategory.id).subscribe( response => {
            this.data.url = this.urlSubcategory;
            this.service.getProductsByUrl(this.data).subscribe(response => {
                this.all_products = [];
                console.log('response products by subcategory', response)
                // console.table(response);
                this.addProducts(response);
                this.showLoading = false;
                this.addItems(666);
            });
        })

        this.service.getCategoryByUrl(this.urlCategory).subscribe((res) => {
            res.subcategories.forEach(element => {
                element.url = this.urlCategory + '/' + element.url;
                this.subcategories.push(element);
            })
        });
        ;*/
        this.service.getCategories().subscribe((res) => {
            res.forEach(element => {
                if (element.category_active) {
                    element.name = element.category_name;
                    element.url = element.url_category;
                    this.allCategories.push(element);
                }
            })
        })
    }

    ngOnInit(): void {
    }

    filter(event) {
        event = event.replaceAll('-', ' ');
        console.log('search by', event)
        this.resultSearch = this.productsSearch.filter(item => item.product_name.toLowerCase().indexOf(event.toLowerCase()) > -1);
        this.resultSearch.forEach(element => {
            if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI) this.maxDI = element.diametro_interno;
            if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE) this.maxDE = +element.diametro_externo;
            if (element.alto != undefined && element.alto > this.maxL) this.maxL = element.alto;
            if (element.price != undefined && element.price > this.maxPrice) this.maxPrice = Math.round(element.price);
        })
        this.all_products = this.resultSearch;
        console.log('this.all_products',this.all_products)
        this.filterbyCategories(this.all_products);
        this.addItems();
        // Swal.fire({
        //     icon: 'success',
        //     title: 'Búsqueda con éxito',
        //     showConfirmButton: false,
        //     timer: 1e3
        // });
        this.userSearch = true;
        this.showLoading = false;
        console.log('result filter', this.resultSearch)
    }

    async getProductsForSearch() {
        this.showLoading = true;
        this.firestore.getWhere('Products', 'product_active', true).subscribe(res => {
            this.productsSearch = [];
            res.forEach(element => {
                if (element.product_available && element.product_active) {
                    let img = {
                        "src": '',
                        "alt": element.product_name,
                    }
                    element.title = element.product_name;
                    element.description = element.product_description || element.specifications;
                    element.brand   = element.marca;
                    element.sale    = element.product_active;
                    element.price   = element.price_tax_product;
                    element.new     = false;
                    element.discount = element.product_discount || element.discount;
                    element.stock = element.product_stock || element.products_stock;
                    element.diametro_interno = Number(element.diametro_interno) || 0;
                    element.diametro_externo = Number(element.diametro_externo) || 0;
                    element.alto = Number(element.alto) || 0;
                    element.images = [];
                    if (element.extra_images != null) {
                        element.extra_images.forEach(index => {
                            img.src = index;
                            element.images.push(img);
                        })
                    }
                    img.src = element.product_imagesm;
                    element.images.push(img);
                    this.productsSearch.push(element);
                }
            });
            this.filter(this.search);
        })
    }

    addItems(type = 0) {
        if (type > 0) {
            // this.finished = true;
            this.products = this.all_products.slice(0, this.addItemCount);
            this.sortByFilter('diametroExterior');
        }
        let newOptionDE: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDE) + ((10 / 100) * Number(this.maxDE))),
        };
        this.optionsDE = newOptionDE;

        let newOptionDI: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxDI) + ((10 / 100) * Number(this.maxDI))),
        };
        this.optionsDI = newOptionDI;

        let newOptionL: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxL) + ((10 / 100) * Number(this.maxL))),
        };
        this.optionsL = newOptionL;

        let newOptionPrice: Options = {
            floor: 0,
            ceil: Math.round(Number(this.maxPrice) + ((10 / 100) * Number(this.maxPrice))),
        };
        this.optionsPrice = newOptionPrice;
        this.updateFilter();
    }

    // Infinite scroll
    public onScroll() {
        this.addProductsPagination();
    }

    // Append filter value to Url
    updateFilter(tags?: any) {
        this.resultSearch = this.all_products.filter(item =>
            item.price >= this.minPrice && item.price <= this.maxPrice &&
            item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE &&
            item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI &&
            item.alto >= this.minL && item.alto <= this.maxL &&
            (this.brands.length > 0 ? this.brands.indexOf(item.category_uid) > -1 : item.price >= this.minPrice && item.price <= this.maxPrice)
        );
    }

    // SortBy Filter
    sortByFilter(value) {
        this.sortBy = value;
        this.resultSearch = this.productService.sortProducts(this.all_products, this.sortBy);

        /*this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {sortBy: value ? value : null},
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });*/
    }

    // Remove Tag
    removeTag(tag) {

        this.brands = this.brands.filter(val => val !== tag);
        this.colors = this.colors.filter(val => val !== tag);
        this.size = this.size.filter(val => val !== tag);

        let params = {
            brand: this.brands.length ? this.brands.join(",") : null,
            color: this.colors.length ? this.colors.join(",") : null,
            size: this.size.length ? this.size.join(",") : null
        }

        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge', // preserve the existing query params in the route
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }

    // Clear Tags
    removeAllTags() {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {},
            skipLocationChange: false  // do trigger navigation
        }).finally(() => {
            this.viewScroller.setOffset([120, 120]);
            this.viewScroller.scrollToAnchor('products'); // Anchore Link
        });
    }


    // Change Grid Layout
    updateGridLayout(value: string) {
        this.grid = value;
    }

    // Change Layout View
    updateLayoutView(value: string) {
        this.layoutView = value;
        if (value == 'list-view')
            this.grid = 'col-lg-12';
        else
            this.grid = 'col-xl-3 col-md-6';
    }

    // Mobile sidebar
    toggleMobileSidebar() {
        this.mobileSidebar = !this.mobileSidebar;
    }

    priceFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minPrice: event.value, maxPrice: event.highValue};
            console.info('Price Filter', value);
            if (!type) {
                this.minPrice = value.minPrice;
                this.maxPrice = value.maxPrice;
            }
            // Price Filter
            this.resultSearch = this.all_products.filter(item => item.price >= this.minPrice && item.price <= this.maxPrice);
            if (!type) this.sortByFilter('low');
            this.updateFilter();
        }
    }

    diametroExteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minDE: event.value, maxDE: event.highValue};
            console.info('diametroExterior Filter', value);
            if (!type) {
                this.minDE = value.minDE;
                this.maxDE = value.maxDE;
            }
            // diametroExterior Filter
            this.resultSearch = this.all_products.filter(item => item.diametro_externo >= this.minDE && item.diametro_externo <= this.maxDE);
            if (!type) this.sortByFilter('diametroExterior');
            this.updateFilter();
        }
    }

    diametroInteriorFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minDI: event.value, maxDI: event.highValue};
            console.info('diametroInterior Filter', value);
            if (!type) {
                this.minDI = value.minDI;
                this.maxDI = value.maxDI;
            }
            // diametroInterior Filter
            this.resultSearch = this.all_products.filter(item => item.diametro_interno >= this.minDI && item.diametro_interno <= this.maxDI);
            if (!type) this.sortByFilter('diametroInterior');
            this.updateFilter();
        }
    }

    largoFilter(event, type = false) {
        if (event.highValue != undefined) {
            let value = {minL: event.value, maxL: event.highValue};
            console.info('Altura Filter', value);
            if (!type) {
                this.minL = value.minL;
                this.maxL = value.maxL;
            }
            // largo Filter
            this.resultSearch = this.all_products.filter(item => item.alto >= this.minL && item.alto <= this.maxL);
            if (!type) this.sortByFilter('altura');
            this.updateFilter();
        }
    }

    private addProducts(response) {
        this.finished = response.products.length == 0;
        response.products.forEach(element => {
            if (element.product_active && element.product_available) {
                let img = {
                    "src": '',
                    "alt": element.product_name,
                }
                element.title = element.product_name;
                element.description = element.specifications;
                element.brand = element.marca;
                element.sale = element.product_active;
                element.discount = element.product_discount || element.discount;
                element.stock = element.products_stock;
                element.price = element.product_price;
                element.diametro_interno = Number(element.diametro_interno) || 0;
                element.diametro_externo = Number(element.diametro_externo) || 0;
                element.alto = Number(element.alto) || 0;
                if (element.diametro_interno != undefined && element.diametro_interno > this.maxDI) this.maxDI = element.diametro_interno;
                if (element.diametro_externo != undefined && +element.diametro_externo > this.maxDE) this.maxDE = +element.diametro_externo;
                if (element.alto != undefined && element.alto > this.maxL) this.maxL = element.alto;
                if (element.price != undefined && element.price > this.maxPrice) this.maxPrice = Math.round(element.price);
                element.images = [];
                if (element.extra_images != null) {
                    element.extra_images.forEach(index => {
                        img.src = index;
                        element.images.push(img);
                    })
                }
                img.src = element.product_imagesm;
                element.images.push(img);
                this.all_products.push(element);
            }
        });
    }

    private async addProductsPagination() {
        this.loading = true;
        this.data.page++;
        let currentPage = this.data.page;
        if (currentPage > 1) {
            currentPage--;
            this.data.offset = this.data.limit * currentPage;
        }
        await this.service.getProductsByUrl(this.data).subscribe(response => {
            console.log('response get products', response);
            this.addProducts(response);
            this.addItems();
        });
        this.loading = false;
    }

    filterbyCategories(products) {
        const uniqueCategory = [];

        products.filter((product) => {
            if (product.category_uid) {
                const index = uniqueCategory.indexOf(product.category_uid)
                if (index === -1) uniqueCategory.push(product.category_uid)
            }
        })
        this.categories = this.allCategories.filter(item => uniqueCategory.indexOf(item.id) > -1);
        console.log('uniqueCategory', uniqueCategory)
        return uniqueCategory
    }

    appliedFilter(event) {
        let index = this.brands.indexOf(event.target.value);  // checked and unchecked value
        console.log('index', index);
        if (event.target.checked)
            this.brands.push(event.target.value); // push in array cheked value
        else
            this.brands.splice(index, 1);  // removed in array unchecked value

        let brands = this.brands.length ? {brand: this.brands.join(",")} : {brand: null};
        console.log('result brands 2', this.brands);
        this.updateFilter();
    }

    // check if the item are selected
    checked(item) {
        if (this.brands.indexOf(item) != -1) {
            return true;
        }
    }
}
